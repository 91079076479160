//TODO : refactor this
//impplement proper cache in setItem and getItem

const storage = (() => {
  const cache: Record<string, any> = {}

  const setItem = (key: string, value: any) => {
    setCachedItem(key, value)

    if (typeof window !== 'undefined' && window.localStorage) {
      return localStorage.setItem(key, value)
    }
  }

  const getItem = (key: string) => {
    const val = getCachedItem(key)
    if (val) {
      return val
    }

    if (typeof window !== 'undefined' && window.localStorage) {
      return localStorage.getItem(key)
    }
  }

  const getCachedItem = (key: string) => {
    return cache[key]
  }

  const setCachedItem = (key: string, value: any) => {
    return (cache[key] = value)
  }

  const removeItem = (key: string) => {
    delete cache[key]
    if (typeof window !== 'undefined' && window.localStorage) {
      return localStorage.removeItem(key)
    }
  }

  return {
    setItem,
    getItem,
    removeItem,
    getCachedItem,
    setCachedItem,
  }
})()

export default storage
