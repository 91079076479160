export * from './formatCost'
export * from './formatDate'

export function subscriptionEnabled(locationId: string) {
  const locations = {
    'barcelona-ct-es': {
      id: 36902,
      image: 'https://api.vermutapp.com/mobile/locations/featured/bcn.png',
    },
    barcelona: {
      id: 36902,
      image: 'https://api.vermutapp.com/mobile/locations/featured/bcn.png',
    },
    madrid: {
      id: 40184,
      image: 'https://api.vermutapp.com/mobile/locations/featured/mad.png',
    },
    'madrid-md-es': {
      id: 40184,
      image: 'https://api.vermutapp.com/mobile/locations/featured/mad.png',
    },
  }

  return locationId in locations ? true : false
}
