'use client'
import { useContext } from 'react'

import { EventTrackingContext } from '@/providers/EventTrackingContext'

export const useTracking = () => {
  const { eventTracking } = useContext(EventTrackingContext)

  return eventTracking
}
