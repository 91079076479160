'use client'

import { useState } from 'react'

import storage from '@/lib/storage'

export const useLocalStorage = () => {
  const [value, setValue] = useState<string | null>(null)

  const setItem = (key: string, value: string) => {
    storage.setItem(key, value)
    // setValue(value)
  }

  const getItem = (key: string) => {
    const value = storage.getItem(key)
    // setValue(value)
    return value
  }

  const removeItem = (key: string) => {
    storage.removeItem(key)
    // setValue(null)
  }

  return { value, setItem, getItem, removeItem }
}
