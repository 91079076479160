'use client'
import Image, { ImageProps } from 'next/image'
import { useState } from 'react'

import clsxm from '@/lib/clsxm'

type NextImageProps = {
  alt: string
  useSkeleton?: boolean
  imgClassName?: JSX.IntrinsicElements['img']['className']
  blurClassName?: string
} & ImageProps

const allowedDomains = [
  'vermut-prod-assets.fra1.cdn.digitaloceanspaces.com',
  'imagedelivery.net',
  'www.vermutapp.com',
  'img.icons8.com',
  'api.vermutapp.com',
  'devapi.vermutapp.com',
  'development.vermutapp.com',
  'stagingapi.vermutapp.com',
  'partnerwebapi.vermutapp.com',
  'staging.vermutapp.com',
  'preprodapi.vermutapp.com',
  'api.typeform.com',
  'platform-lookaside.fbsbx.com',
  'lh3.googleusercontent.com',
]

/**
 *
 * @description Must set width using `w-` className
 * @param useSkeleton add background with pulse animation, don't use it if image is transparent
 */
export default function NextImage({
  useSkeleton = false,
  src,
  width,
  height,
  alt,
  className,
  imgClassName,
  blurClassName,
  ...rest
}: NextImageProps) {
  const [status, setStatus] = useState(useSkeleton ? 'loading' : 'complete')
  const widthIsSet = className?.includes('w-') ?? false
  const heightIsSet = className?.includes('h-') ?? false

  const innerStyle: { width?: string; height?: string } = {}

  if (!widthIsSet) {
    innerStyle.width = `${width}px`
  }

  if (!heightIsSet) {
    innerStyle.height = `${height}px`
  }

  let unoptimized = true

  if (typeof src === 'string') {
    const srcUrl = new URL(src)

    unoptimized = allowedDomains.includes(srcUrl.hostname) ? false : true
    if (srcUrl.hostname === 'imagedelivery.net') {
      unoptimized = true
    }
  }

  return (
    <figure style={innerStyle} className={className}>
      <Image
        className={clsxm(className, imgClassName, status === 'loading' && clsxm('animate-pulse', blurClassName))}
        src={src}
        width={width}
        height={height}
        alt={alt}
        onLoad={() => {
          setStatus('complete')
        }}
        {...rest}
        unoptimized={unoptimized}
      />
    </figure>
  )
}

// imagedelivery => CloudFlareImage
// _next img
// fallback =>  NextImage
